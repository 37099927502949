'use strict';

app || (app = {});

/** Web content accessibility. */
app.wca = {
	_vars: {
		defaultOptions: {
			enable: [
				'landmarkroles', 'searchform', 'headercart', 'shippingmethods',
				'labelcheck', 'slider', 'productimages', 'productlist', 'imagezoom'
			]
		}
	},
	init: function(opts) {
		var defaults = this._vars.defaultOptions;
		var options = $.extend(defaults, opts || {});

		this._init(options);
	},
	_init: function(options) {
		options || (options = {});

		var self = this;
		var enableOpt = options.enable;

		if (enableOpt.length > 0) {
			enableOpt.forEach(function(name) {
				if (self.apply[name]) {
					self.apply[name]();
				}
			});
		}
	},
	apply: {
		landmarkroles: function() {
			var utils = app.wca.utils;
			var regionsArr = [
				{
					selector: '._ > .top',
					role: 'banner'
				},
				{
					selector:  $('.d1').closest('.main:not(.for-correction)'),
					role: 'main'
				},
				{
					selector: '._ .footer',
					role: 'contentinfo'
				},
				{
					selector: '._ > .top .menu',
					label: 'Main',
					role: 'navigation'
				},
				{
					selector: '._ .bottom-menu, ._ .footer-simple-list',
					label: 'Footer',
					role: 'navigation'
				},
				{
					selector: '._ .d2 .li-block.cust-category',
					labelledby: 'h3',
					role: 'navigation'
				},
				{
					selector: '._ .d2 .li-block.category',
					labelledby: 'h3',
					role: 'navigation'
				}
			];
			var $footerLinks = $('._ .footer-links > .footer-text');

			for (var i = 0; i < regionsArr.length; i++) {
				var item = regionsArr[i];
				var $item = typeof item.selector === 'string' ? $(item.selector) : item.selector;

				utils.addAttr($item, 'role', item.role);

				if (item.label) {
					utils.addAttr($item, 'aria-label', item.label);
				}

				if (item.labelledby) {
					var $title = $('> h3', $item);
					var titleId = $title.attr('id');

					if (titleId) {
						utils.addAttr($item, 'aria-labelledby', titleId);
					}
				}
			}

			if ($footerLinks.length > 0) {
				$footerLinks.each(function() {
					var $item = $(this);
					var $title = $('> h4', $item);
					var titleId = $title.attr('id');

					utils.addAttr($item, 'role', 'navigation');

					if (titleId) {
						utils.addAttr($item, 'aria-labelledby', titleId);
					}
				});
			}
		},
		searchform: function() {
			var utils = app.wca.utils;
			var label = app.lang.selectWord('Search', 'Recherche');

			utils.addAttr($('.search-form'), 'role', 'search');
			utils.addAttr($('.search-form input'), 'aria-label', label);
			utils.addText($('.search-form button'), label, true);
			utils.addText($('.search-btn'), label, true);

			$('._ input.hidden').prop('hidden', 'true');
		},
		headercart: function() {
			var utils = app.wca.utils;
			var label = app.lang.selectWord('My сart', 'Mon panier');

			utils.addText($('.top .my-cart button'), label, true);
		},
		shippingmethods: function() {
			var utils = app.wca.utils;
			var text = app.lang.selectWord('Select', 'Sélectionnez');

			utils.addText($('.shipping-methods th.tools'), text);
		},
		labelcheck: function() {
			var utils = app.wca.utils;

			utils.replaceTag($('.product-view .description .name > label'), 'span');
			utils.replaceTag($('.description .sku > label'), 'span');
			utils.replaceTag($('.subtotal > label'), 'span');
			utils.replaceTag($('#payment-method-list > li > label.wrapper'), 'span');
		},
		slider: function() {
			var $slider = $('#slider');

			$('> a', $slider).each(function() {
				var $el = $(this);
				var $img = $el.find('img');

				if ($el.attr('title')) {
					$el.removeAttr('title');
				}

				if ($img.length && $img.attr('title')) {
					$img.removeAttr('title');
				}
			});
			$('.nivo-caption', $slider).remove();
		},
		productimages: function() {
			var text = app.lang.selectWord('A picture showing ', 'Une photo montrant ');

			$('.product-view img').each(function() {
				var $el = $(this);
				var alt = $el.attr('alt');
				var title = $el.attr('title');

				if (alt === title) {
					$el.attr('alt', text + alt);
				}
			});
		},
		productlist: function() {
			$('.product-list2 .item').each(function() {
				var $el = $(this);
				var $sku = $('.sku', $el);

				if ($sku.length && $sku.attr('title')) {
					$sku.removeAttr('title');
				}
			});
		},
		imagezoom: function() {
			var utils = app.wca.utils;
			var $zoom = $('.zoom, .zoom-advanced', '.product-view');

			utils.addAttr($zoom, 'aria-hidden', 'true');
			utils.addAttr($('> a', $zoom), 'aria-hidden', 'true');
			utils.addAttr($('> a', $zoom), 'tabindex', '-1');
		}
	},
	utils: {
		addAttr: function($el, attrName, attrData) {
			if ($el.length > 0 && !$el.attr(attrName)) {
				$el.attr(attrName, attrData);
			}
		},
		addText: function($el, textData, optHidden, textCheck) {
			textCheck = textCheck !== undefined ? textCheck : $el.text().trim();

			if ($el.length > 0 && !textCheck) {
				if (optHidden) {
					$el.append('<span class="visually-hidden">' + textData + '</span>');
				} else {
					$el.text(textData);
				}
			}
		},
		replaceTag: function($el, newTag) {
			if ($el.length > 0 && (newTag === 'div' || newTag === 'span')) {
				$el.replaceWith(function(idx, el) {
					var cls = $(this).attr('class');
					var $result = $('<' + newTag + '>');
					if (cls) {
						$result.attr('class', cls);
					}
					return $result.html(el);
				});
			}
		}
	}
}