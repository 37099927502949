$(
	 function() {
		 $( window ).scroll(
			  function(){
				  app.floatingHeader();
			  }
		 );

		 $("._").before('<div class="top-fix"></div>');

         app.initializeScrollButton();
		 app.assignNumberControl();
		 app.setSelectedLink();
		 app.addRegisterCheckBoxes();
		 app.assignProductOver();
		 app.attachSvgEvents();
		 app.makeSocialButtons();
         app.addCoverSpan();
		 app.setBackUrls();
		 app.initACBL();
		 app.correctTopMenuPopups();
         app.saveProductListInfo();
         app.setMenuColors();
		 app.floatingHeader();

         app.hamburger.init();

         $('.q-simple .div-search-field input').focus(
         	function() {
         		$('.header').addClass('sch-focused');
			}
		 ).blur(
             function() {
                 $('.header').removeClass('sch-focused');
             }
		 );

         app.events.addListener(
         	function(e) {
         		if (e.data.params.speed === 10000000) {
                    e.data.params.count = 5;
				}
			},
			'udu_BEFORE-MAKE-CAROUSEL-DONE'
		 );

         if (app._vars.bindFancyId) {
         	app.bindFancyById(app._vars.bindFancyId);
		 }

	 }
);

var app = {
	_vars: {
		hideHtmlReportOrderDiscountInfo: true,
		topWarnInfo: {
			'._ .top .top-line': 'margin-top'
		},
		baseEvents: {
			'udu-GST-PRICE-LIST-DONE': function(e) {
				var $place = e.data.$place;
				var $item = $place.closest('.item');
				var $price = $item.find('.price').addClass('_done');
				var price = e.data.val;
				if (price !== '' && Number(price) >= 0) {
					$price.find('> .money').html(app.money.toDollar(price));
				}
				$price.fadeTo(
					0,
					0,
					function() {
						$price.fadeTo(300, 1);
					}
				)
			}
		},
		v: 7
	},
	_VERSION: 2,
	_VERSION_TRANS_NOT_COMPLETED: true,
    messages : {},
    indexLinkPos : 0,
	fixTop : 100,
    productAvailableQuantityUrl : null,
	optionsWereInitialized : false,
	prodItemCorrection: 15,
	doAddPlIcons: function ($item, html) {
		$item.find('> .image').append(html);
		$item.find('.pl-icons > div').addClass('udu__jelly-item');
	},
	fixMSRP: function() {
		var $span = $('.udu__dtl-msrp span:nth-child(2)');
		var val = $span.html();
		if (Number(val)) {
			$span.html(app.money.toDollar(val));
		}
	},
	showSearch : function() {
		if ($('.li-search .ul-mega-1').hasClass('hidden')) {
			$('.li-search').addClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').removeClass('hidden');
		} else {
			$('.li-search').removeClass('hover');
			$('.li-search .ul-mega-1, .li-search .delimiter').addClass('hidden');
		}
	},
	setMenuColors : function() {
        $('._ .with-children > ul').addClass('schema-brd-color');
		$('._ .with-children ul li').addClass('schema-bg-color-over');
		$('._ div.delimiter').addClass('schema-brd-color schema-bg-color');
	},
	doBeforePriceSliderShown: function(trg) {
		var color = $('._ button.details, ._ .subscribe form button, .button').css('background-color');
		$('.noUi-connect').css('background-color', color);
	},
	bindFancyById: function(id) {
        app.getScript(
            [
                'fancybox/fancybox.min.css',
                'fancybox/fancybox.min.js'
            ],
            function() {
            	var src = $('#' + id).find('img').attr('src');
            	if (!src) {
            		return false;
				}
                $('a[href="#' + id +'"]').fancybox({content: '<img style="width:' + ($(window).width() * .8) + 'px" src="' + src + '">'});
            }
        )
	},
	doAfterProdListAttributesDone: function ($list, savedData) {
		$list.each(
			function () {
				var $this = $(this);
				var $item = $this.closest('.item');
				var href = $this.attr('href');
				if (!href) {
					href = $this.data('href');
				}

				var prodId = app.prodListAttrs.getIdByHref(href);

				var str = '';
				if (savedData[prodId] && savedData[prodId]['msrp']) {
					var vl = savedData[prodId]['msrp'];
					if (Number(vl)) {
						vl = app.money.toDollar(vl);
					}
					var msrp = 'MSRP';
					if (app.getLang() == 'fr') {
						msrp = 'PDSF';
					}
					str = '<span class="udu__msrp-label" data-udu-editor="editor2">' + msrp + '</span><span class="udu__msrp-value" data-udu-editor="editor2">' + vl  + '</span>';
				}
				$item.find('.price').after('<span class="udu__msrp">' + str + '</span>');
			}
		);
	},
};