"use strict";
app.bootstrap = {
	_var: {},
	init: function() {
		app.bootstrap.setGlobalAjaxHandler();

		var $div = $('div.bootstrap');
		if ($div.length === 0) {
			return false;
		}

		if ($div.length === 1) {
			$div.addClass('udu__main-bootstrap');
		}

		$('.top a.reg-me').addClass('do-not-move');

		app.bootstrap.defineIconsFont($div);

		app.events.addListener(
			'udu_DOC-MUTATION',
			function() {
				var $div = $('div.bootstrap');
				app.bootstrap.fixOrderHistor($div);
				if ($div.find('div, table, input').length > 0 && !app._vars._bootstrapCompleteEventSent) {
					app._vars._bootstrapCompleteEventSent = true;
					app.events.trigger(
						'udu_BOOTSTRAP-BLOCK-DONE',
						{
							bootDiv: $div
						}
					)
				}

				app.bootstrap.fixSearchForm();

				if (!$div.hasClass('_done-2') && $div.data('widget-endpoint-url') === ':root/user/log-in' && $div.find('input').length > 0) {
					$div.addClass('_done-2')
					var $errMess = $div.find('.message.error:not(._done');
					var $login = $div.find('input[name=login]');
					var $pass = $div.find('input[name=password]');
					$login.attr('aria-label', app.lang.selectWord('Enter Email here', 'Entrez Email ici'));
					$pass.attr('aria-label', app.lang.selectWord('Enter Password here', 'Entrez Mot de passe ici'));

					if ($errMess.length >= 1) {
						$errMess.addClass('_done');
						app.utils.effects.shakeIt($div);
					}

					$('a[href*="/request-password-reset"]')
						.attr('aria-label', app.lang.selectWord('Click here to reset the Password', 'Cliquez ici pour réinitialiser le mot de passe'));

					$('.bootstrap a').removeAttr('data-udu-selector').each(
						function() {
							var $this = $(this);
							if (!$this[0].className) {
								$this.addClass('udu__boot-gen-link')
							}
						}
					);

					var $logLink = $('input[data-app-field-path=login]').closest('div.value')
						.find('.udu__boot-gen-link')
						.addClass('udu__boon-login-link');

					var $forgotLink = $('input[data-app-field-path=password]').closest('div.value')
						.find('.udu__boot-gen-link')
						.addClass('udu__boot-forgot-link');

					var $logH3 = $('div[data-widget-endpoint-url*=":root/user/log-in"]').find('h3');
					if ($logH3.length === 1 && !$logH3.hasClass('_done')) {
						$logH3.addClass('_done udu__boot-h3-login-page');
						if (app.messages['udu_loginPageH3Title']) {
							$logH3.html(app.messages['udu_loginPageH3Title']);
						}
					}

					if ($logLink.length === 1 && !app.cookies._vars._cookiesAreChecked) {
						app.cookies._vars._cookiesAreChecked = true;
						if (!app.cookies.isActive()) {
							app.errorMsg('udu_CookiesDisabledWarning');
						}
					}

                    if (app.messages['cfg__loginFormButtonCaption']) {
                        $('.bootstrap[data-widget-activate-on-url=":root/user/log-in"] .ui-toolbar button.app-model-post')
                            .html(app.messages['cfg__loginFormButtonCaption']);
                    }

					if (app.messages['cfg__loginInputPlaceHolder']) {
						var $inp = $('.bootstrap[data-widget-activate-on-url=":root/user/log-in"] input[name="login"]');
                        $inp.attr('placeholder', app.messages['cfg__loginInputPlaceHolder']);
                    }

					if ($logLink.length === 1 && !$logLink.hasClass('_done') && app.messages['udu_registerLinkText_Login']) {
						$logLink.addClass('_done');
						if (app.messages['udu_registerLinkText_Login_Link_YN'] === 'N') {
							$logLink.after('<span data-udu-editor="editor2" data-udu-save-method="file" data-udu-content-id="udu_registerLinkText_Login">' + app.messages['udu_registerLinkText_Login'] + '</span>');
							$logLink.remove();

							if ($('.udu__smart-login-nav').length > 0) {

							}

						} else {
							$logLink.html(app.messages['udu_registerLinkText_Login']);
						}
					}

					if ($forgotLink.length === 1 && !$forgotLink.hasClass('_done') && app.messages['udu_registerLinkText_Forgot']) {
						$forgotLink.addClass('_done');
						if (app.messages['udu_forgotLinkText_Mode_YN'] === 'N') {
							$forgotLink.after('<span data-udu-editor="editor2" data-udu-save-method="file" data-udu-content-id="udu_registerLinkText_Forgot">' + app.messages['udu_registerLinkText_Forgot'] + '</span>');
							$forgotLink.remove();
						} else {
							$forgotLink.html(app.messages['udu_registerLinkText_Forgot']);
						}
					}

					app.bootstrap.addTogglePwd({ $pass });

					if (app.loginFormDesign && app._vars.loginFormTemplate) {
						app.loginFormDesign.init(app._vars.loginFormTemplate);
					}

					var $txt1 = $('#udu_underLogInputsText:not(._done)');
					if ($txt1.length === 1) {
						$('.container-fluid').after($txt1.addClass('_done').removeClass('hidden'));
					}

					if ($('.udu__smart-login-nav').length > 0) {
						$login.keydown(
							function(e) {
								if (e.key === 'Tab') {
									e.preventDefault();
									$('input[name=password]').focus();
								}
							}
						);

						$pass.keydown(
							function(e) {
								if (e.key === 'Tab') {
									var $a = $('a[data-udu-content-id=udu_registerLinkText_Login]');
									if ($a.length === 0) {
										$('a[data-udu-content-id=udu_registerLinkText_Forgot]');
									}
									if ($a.length > 0) {
										e.preventDefault();
										$a.focus();
									}
								}
							}
						);

						app.utils.setTimeout(
							function() {
								$('a[data-udu-content-id=udu_registerLinkText_Login]').keydown(
									function(e) {
										if (e.key === 'Tab') {
											var $a = $('a[data-udu-content-id=udu_registerLinkText_Forgot]');
											if ($a.length > 0) {
												e.preventDefault();
												$a.focus();
											}
										}
									}
								)
							},
							1000,
							'udu_registerLinkText_Login.f'
						);
					}

					if (window.admin) {
						admin.assignEditor();
					}
				}
			}
		)
	},
	defineIconsFont: function ($div) {
		if (!$div.length) {
			return;
		}

		if (app.fonts.fontLineariconsExists()) {
			$div.addClass('udu__linear-icons');
		} else if (app.fonts.fontMaterialIconsExists()) {
			$div.addClass('udu__material-icons');
		} else if (app.fonts.fontAwesomeExists()) {
			$div.addClass('udu__fontawesome-icons');
		} else {
			$div.addClass('udu__no-defined-icons-font');
		}
	},
	fixOrderHistor: function($div) {
		$('button.btn-default').removeClass('btn-default');
		var $buttons = $div.find('button[onclick*="\\"/put-into-cart\\""]:not(._done)');
		$buttons.attr('onclick', '/*--corrected--*/').addClass('_done udu__reorder-products-buttons custom-button');
		var $tds = $('.ui-field-status');
		$tds.closest('table').addClass('udu__history-order-table');
		$tds.each(
			function() {
				var $this = $(this);
				var html = $this.html();
				if (html.indexOf('<') < 0) {
					$this.addClass('udu__status-' + html.replace(/ /g, '_').toLowerCase())
						.html('<span class="udu__status-span-wrp"><span class="udu__status-span">' + html + '</span></span>');
				}
			}
		);

		if (window.admin) {
			admin.assignEditor();
		}

		$buttons.click(
			function() {
				var ids = EntityUtils.getSelectedIds(this, "products");
				if (!ids || ids.length === 0) {
					app.alertMsg('_you_Did_Not_Select_Reorder');
					return false;
				}
				var url = $(this).closest("[data-widget-type=table]").data("widgetEndpointUrl") + "/put-into-cart";
				app.blockWindow(
					true,
					true,
					function() {
						$.ajax({
							url: url,
							type: 'POST',
							data: {
								ids: ids
							},
							complete: function(res) {
								var data = res.responseJSON;
								if (data.messageSuccess) {
									app.alert(
										data.messageSuccess,
										function() {
											app.url.goCart();
										}
									)
								} else if (data.messageError) {
									app.alert(
										data.messageError
									);
								}
							}
						});
					},
					'P_W'
				)
			}
		)
	},
	fixSearchForm: function() {
		if (app._usrLogged) {
			var $orderHistorySearchForm = $('div[data-widget-type="search-form"]');

			if ($orderHistorySearchForm.length && !$orderHistorySearchForm.hasClass('_done-2')) {
				$orderHistorySearchForm.addClass('bootstrap _done _done2');
				app.bootstrap.defineIconsFont($orderHistorySearchForm);
			}
		}
	},
	setGlobalAjaxHandler: function() {
		if (app.bootstrap._var.setGlobalAjaxHandler_Done) {
			return false;
		}
		app.bootstrap._var.setGlobalAjaxHandler_Done = true;
		$(document).ajaxComplete(function(e, answer, dtl) {
			if (!dtl) {
				return false;
			}
			var url = dtl.url;
			var stat = Number(answer.status);

			// shake bootstrap - incorrect password
			if (stat === 400 && url.indexOf('user/log-in') > 0) {
				app.utils.effects.shakeIt('.bootstrap');
			}

		});
	},
	addTogglePwd: function ({ $pass }) {
		if (!$pass.length) {
			return;
		}

		$pass.parent().append('<span class="toggle-pwd" id="toggle-pwd" aria-hidden="true" type="button"></span>');

		var pwdShown = 0;

		$('#toggle-pwd').on('click', function() {
			var $p = $('input[name=password]');

			if (!pwdShown) {
				pwdShown = 1;
				$p.attr('type', 'text');
				$(this).addClass('is-shown');
			} else {
				pwdShown = 0;
				$p.attr('type', 'password');
				$(this).removeClass('is-shown');
			}
		});
	}
};
